<!--
 * @Author: Han
 * @Date: 2021-11-09 15:41:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-10 21:10:34
 * @FilePath: /HanSharePage/src/App.vue
-->
<template>
  <div class="HanWeb">
    <HanHead />
    <router-view />
    <HanCon v-if="$store.state.VideoLinks.length > 1" />
    <el-drawer title="全在这儿啦" :visible.sync="orShow" direction="ltr" size="78%" style="max-width:666px"
      :wrapperClosable="false">
      <Link :orShow="orShow" @setShow="setShow" />
    </el-drawer>
    <Footer @setShow="setShow" />
  </div>
</template>

<script>
export default {
  components: {
    HanHead: () => import("@/components/HanHead"),
    HanCon: () => import("@/components/Content"),
    Footer: () => import("@/components/Footer"),
    Link: () => import("@/components/Link")
  },
  data() {
    return {
      orShow: false
    };
  },
  created() {
    console.group(
      "%c Name Han",
      "color: #ffffff; background: #6666FF; padding:5px"
    );
    console.log(
      "%c \u96BE\u5FD8\u548C\u96BE\u8FC7\u603B\u90FD\u662F\u4F1A\u6709\u7684\u5427",
      "color: #ffffff; background: #6666FF; padding:5px"
    );
    console.log(
      "%c \u90A3\u4E9B\u4EBA\u548C\u4E8B\u60C5\u603B\u90FD\u662F\u4F1A\u6709\u7684\u5427",
      "color: #ffffff; background: #6666FF; padding:5px"
    );
    console.log(
      "%c \u660E\u4EAE\u4E0E\u6666\u6697\u603B\u90FD\u662F\u4F1A\u6709\u7684\u5427",
      "color: #ffffff; background: #6666FF; padding:5px"
    );
    console.groupEnd();
    const vname = this.$route.params
    vname.videoName ? this.$store.commit('setKey', vname.videoName) : this.$router.push({
      name: 'Home'
    })
    const tipsStatus = localStorage.getItem('hansShare');
    if (!tipsStatus) {
      this.$notify({
        title: '提示',
        message: '更新灼灼风流超前点播 全集！',
        offset: 100,
        type: 'success',
        duration: 99999
      });
      localStorage.setItem('hansShare', true)
    }
  },
  methods: {
    setShow() {
      this.orShow = !this.orShow
      this.$store.commit('chanColor')
    }
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 0;
}
</style>
<style lang="less" scoped>
* {
  -webkit-tap-highlight-color: transparent;
}

.HanWeb {
  box-sizing: border-box;
  padding: 0 3px;
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 1000px;
  height: 100px;
}
</style>