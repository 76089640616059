/*
 * @Author: Han
 * @Date: 2021-12-29 14:52:03
 * @LastEditors: Han
 * @LastEditTime: 2021-12-29 17:33:59
 * @FilePath: \1\minepage\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import {
  vvhanDATAS
} from "@/assets/static/data/VideoData";

const links = []
vvhanDATAS.forEach(itm => {
  itm.type && links.push({
    name: itm.name,
    key: itm.key
  })
});

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    vvhanDATAS,
    LinkUrls: links,
    videoUrl: '',
    VideoLinks: [],
    headTitle: '你迷路啦~',
    COLORS: ["#8E8E8E", "#EA0000", "#FF359A", "#FF00FF", "#9F35FF", "#4A4AFF", "#0080FF", "#00E3E3",
      "#02F78E", "#00EC00",
      "#9AFF02", "#E1E100", "#EAC100", "#FF9224", "#FF6809", "#C48888", "#B9B973", "#81C0C0",
      "#A6A6D2", "#C07AB8"
    ],
    COLORNUM: []
  },
  mutations: {
    setKey(s, t) {
      s.VideoLinks = []
      s.headTitle = '你迷路啦~'
      s.videoUrl = ''
      vvhanDATAS.forEach(itm => {
        if (itm.key == t) {
          s.headTitle = itm.name
          s.VideoLinks = itm.urls
          s.videoUrl = itm.urls[0]
        }
      })
    },
    setVideoUrl(s, v) {
      s.videoUrl = v
    },
    chanColor(s) {
      const numsArr = [];
      const len = s.LinkUrls.length
      for (let i = 0; i < len; i++) {
        numsArr.push(Math.round(Math.random() * 19))
      }
      s.COLORNUM = numsArr
    }
  }
})