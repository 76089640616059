/*
 * @Author: Han
 * @Date: 2021-12-29 14:09:21
 * @LastEditors: Han
 * @LastEditTime: 2021-12-29 14:53:05
 * @FilePath: \1\minepage\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Videos from '@/components/Player'

Vue.use(VueRouter)

const routes = [{
  path: '/:videoName',
  name: 'Home',
  component: Videos
}]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router