<!--
 * @Author: Han
 * @Date: 2021-12-24 13:49:53
 * @LastEditors: Han
 * @LastEditTime: 2021-12-29 18:05:32
 * @FilePath: \HanSharePage\src\components\Player.vue
-->
<template>
    <div class="vvhan-iframe">
        <iframe v-if="$store.state.videoUrl" ref="player" allowfullscreen="true" :src="`https://www.vvhan.com/hanplayer/?url=${$store.state.videoUrl}`" scrolling="no" frameborder="0"
            width="100%" :height="`${theHeight}px`"></iframe>
        <el-empty v-else description="你迷路啦~" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                theHeight: null,
                orCreat: true
            }
        },
        watch: {
            '$store.state.videoUrl'() {
                this.orCreat && this.creatWin()
            }
        },
        mounted() {
            if (this.$store.state.videoUrl) {
                this.orCreat = false
                this.creatWin();
            }
        },
        methods: {
            creatWin() {
                this.orCreat = false
                window.addEventListener('resize', this.getHeight);
                this.getHeight()
            },
            getHeight() {
                const _this = this
                _this.$nextTick(function () {
                    _this.theHeight = _this.$refs.player.offsetWidth * 9 / 16
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .vvhan-iframe iframe {
        margin-bottom: 36px;
        background-color: #000;
    }
</style>
