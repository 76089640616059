/*
 * @Author: Han
 * @Date: 2021-12-29 14:52:03
 * @LastEditors: Han
 * @LastEditTime: 2021-12-29 15:03:35
 * @FilePath: \1\minepage\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import elementArr from '@/plugins/element'
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false

Object.values(elementArr).forEach(itm => {
  Vue.use(itm)
});

import {
  Notification
} from 'element-ui'
Vue.prototype.$notify = Notification;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')