/*
 * @Author: Han
 * @Date: 2021-11-09 15:55:55
 * @LastEditors: Han
 * @LastEditTime: 2021-12-24 17:56:14
 * @FilePath: \新建文件夹\HanSharePage\src\plugins\element.js
 */

import {
    Button,
    Tag,
    Row,
    Col,
    Collapse,
    CollapseItem,
    Empty,
    Drawer,
} from 'element-ui'

export default {
    Button,
    Tag,
    Row,
    Col,
    Collapse,
    CollapseItem,
    Empty,
    Drawer,
}