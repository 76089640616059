/*
 * @Author: Han
 * @Date: 2021-11-19 17:12:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-10 21:09:35
 * @FilePath: /HanSharePage/src/assets/static/data/VideoData.js
 */

export const vvhanDATAS = [
  {
    key: 'XuZhou-MiShi',
    name: '密室片段',
    type: false,
    urls: [
      'https://static.4ce.cn/ipfs/QmcoMHPf9dhiyRWfSAa4DfU6Sgw1ddGXGesrspqVmtDM7u',
      'https://static.4ce.cn/ipfs/QmUaHjbQYCRhm6Tefq2vQFLEXVqJP4RydfDbGHD1wCcPZr',
      'https://static.4ce.cn/ipfs/QmUaHjbQYCRhm6Tefq2vQFLEXVqJP4RydfDbGHD1wCcPZr',
      'https://static.4ce.cn/ipfs/QmTQTXYoNruCiEsi1bDmJYYUAZfhTVW3qaRGPJDQ1Atnpk',
    ],
  },
  {
    key: 'CRLXS',
    name: '成人练习生',
    type: true,
    urls: [
      'https://store.vvhan.com/d/Onedrive/Video/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F%E7%AC%AC01%E9%9B%86.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F%E7%AC%AC02%E9%9B%86.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F%E7%AC%AC03%E9%9B%86.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F%E7%AC%AC04%E9%9B%86.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F%E7%AC%AC05%E9%9B%86.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F%E7%AC%AC06%E9%9B%86.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F/%E6%88%90%E4%BA%BA%E7%BB%83%E4%B9%A0%E7%94%9F%E7%AC%AC07%E9%9B%86.mp4',
    ],
  },
  {
    key: 'WFKJDT',
    name: '无法抗拒的他',
    type: true,
    urls: [
      'https://store.vvhan.com/d/Onedrive/Video/%E6%97%A0%E6%B3%95%E6%8A%97%E6%8B%92%E7%9A%84%E4%BB%96/Nevertheless.2021.EP01.HD1080P.X264.AAC.Korean.CHS.Mp4er.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%97%A0%E6%B3%95%E6%8A%97%E6%8B%92%E7%9A%84%E4%BB%96/Nevertheless.2021.EP02.HD1080P.X264.AAC.Korean.CHS.Mp4er.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%97%A0%E6%B3%95%E6%8A%97%E6%8B%92%E7%9A%84%E4%BB%96/Nevertheless.2021.EP03.HD1080P.X264.AAC.Korean.CHS.Mp4er.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%97%A0%E6%B3%95%E6%8A%97%E6%8B%92%E7%9A%84%E4%BB%96/Nevertheless.2021.EP04.HD1080P.X264.AAC.Korean.CHS.Mp4er.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%97%A0%E6%B3%95%E6%8A%97%E6%8B%92%E7%9A%84%E4%BB%96/Nevertheless.2021.EP05.HD1080P.X264.AAC.Korean.CHS.Mp4er.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%97%A0%E6%B3%95%E6%8A%97%E6%8B%92%E7%9A%84%E4%BB%96/Nevertheless.2021.EP06.HD1080P.X264.AAC.Korean.CHS.Mp4er.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%97%A0%E6%B3%95%E6%8A%97%E6%8B%92%E7%9A%84%E4%BB%96/Nevertheless.2021.EP07.HD1080P.X264.AAC.Korean.CHS.Mp4er.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%97%A0%E6%B3%95%E6%8A%97%E6%8B%92%E7%9A%84%E4%BB%96/Nevertheless.2021.EP08.HD1080P.X264.AAC.Korean.CHS.Mp4er.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%97%A0%E6%B3%95%E6%8A%97%E6%8B%92%E7%9A%84%E4%BB%96/Nevertheless.2021.EP09.HD1080P.X264.AAC.Korean.CHS.Mp4er.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E6%97%A0%E6%B3%95%E6%8A%97%E6%8B%92%E7%9A%84%E4%BB%96/Nevertheless.2021.EP10.HD1080P.X264.AAC.Korean.CHS.Mp4er.mp4',
    ],
  },
  {
    key: 'KangFen',
    name: '亢奋 第一季',
    type: true,
    urls: [
      'https://store.vvhan.com/d/Onedrive/Video/%E4%BA%A2%E5%A5%8B/%E8%BF%B7%E9%86%89.Euphoria.S01E01.%E4%B8%AD%E8%8B%B1%E5%AD%97%E5%B9%95.WEB-1080P.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E4%BA%A2%E5%A5%8B/%E8%BF%B7%E9%86%89.Euphoria.S01E02.%E4%B8%AD%E8%8B%B1%E5%AD%97%E5%B9%95.WEB-1080P.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E4%BA%A2%E5%A5%8B/%E8%BF%B7%E9%86%89.Euphoria.S01E03.%E4%B8%AD%E8%8B%B1%E5%AD%97%E5%B9%95.WEB-1080P.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E4%BA%A2%E5%A5%8B/%E8%BF%B7%E9%86%89.Euphoria.S01E04.%E4%B8%AD%E8%8B%B1%E5%AD%97%E5%B9%95.WEB-1080P.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E4%BA%A2%E5%A5%8B/%E8%BF%B7%E9%86%89.Euphoria.S01E05.%E4%B8%AD%E8%8B%B1%E5%AD%97%E5%B9%95.WEB-1080P.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E4%BA%A2%E5%A5%8B/%E8%BF%B7%E9%86%89.Euphoria.S01E06.%E4%B8%AD%E8%8B%B1%E5%AD%97%E5%B9%95.WEB-1080P.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E4%BA%A2%E5%A5%8B/%E8%BF%B7%E9%86%89.Euphoria.S01E07.%E4%B8%AD%E8%8B%B1%E5%AD%97%E5%B9%95.WEB-1080P.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E4%BA%A2%E5%A5%8B/%E8%BF%B7%E9%86%89.Euphoria.S01E08.%E4%B8%AD%E8%8B%B1%E5%AD%97%E5%B9%95.WEB-1080P.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E4%BA%A2%E5%A5%8B/%E8%BF%B7%E9%86%89.Euphoria.%E7%89%B9%E5%88%AB%E7%AF%871.%E4%B8%AD%E8%8B%B1%E5%AD%97%E5%B9%95.WEB-1080P.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E4%BA%A2%E5%A5%8B/%E8%BF%B7%E9%86%89.Euphoria.%E7%89%B9%E5%88%AB%E7%AF%872.%E4%B8%AD%E8%8B%B1%E5%AD%97%E5%B9%95.WEB-1080P.mp4',
    ],
  },
  {
    key: 'JSC-2019',
    name: '寄生虫',
    type: true,
    urls: [
      'https://store.vvhan.com/d/Onedrive/Video/%E5%AF%84%E7%94%9F%E8%99%AB.Parasite.2019.BD1080P.%E9%9F%A9%E8%AF%AD%E4%B8%AD%E5%AD%97.mp4',
    ],
  },
  {
    key: 'ZhiZhuXia-WuGui',
    name: '蜘蛛侠：英雄无归',
    type: true,
    urls: [
      'https://oss.4ce.cn/api?path=/Videos/%E7%94%B5%E5%BD%B1/%E6%BC%AB%E5%A8%81/%E8%9C%98%E8%9B%9B%E4%BE%A0%20%E8%8B%B1%E9%9B%84%E6%97%A0%E5%BD%92/Spider.Man.No.Way.Home.2021.V2.1080p.HDCAM-C1NEM4.mkv&raw=true',
    ],
  },
  {
    key: 'SNB',
    name: '少年班',
    type: true,
    urls: [
      'https://oss.4ce.cn/api?path=/Videos/%E7%94%B5%E5%BD%B1/%E5%B0%91%E5%B9%B4%E7%8F%ADWEB-DL.1080p.mp4&raw=true',
    ],
  },
  {
    key: 'WUSHAN',
    name: '雾山五行',
    type: true,
    urls: [
      'https://store.vvhan.com/d/Onedrive/Video/%E9%9B%BE%E5%B1%B1%E4%BA%94%E8%A1%8C/%E9%9B%BE%E5%B1%B1%E4%BA%94%E8%A1%8C01.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E9%9B%BE%E5%B1%B1%E4%BA%94%E8%A1%8C/%E9%9B%BE%E5%B1%B1%E4%BA%94%E8%A1%8C02.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/%E9%9B%BE%E5%B1%B1%E4%BA%94%E8%A1%8C/%E9%9B%BE%E5%B1%B1%E4%BA%94%E8%A1%8C03.mp4',
    ],
  },
  {
    key: 'ZGC',
    name: '中国城 차이나타운',
    type: true,
    urls: [
      'https://store.vvhan.com/d/Onedrive/Video/%E4%B8%AD%E5%9B%BD%E5%9F%8E.1080p..mkv',
    ],
  },
  {
    key: 'DARLING',
    name: 'DARLING in the FRANXX',
    type: true,
    urls: [
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/1.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/2.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/3.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/4.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/5.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/6.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/7.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/8.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/9.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/10.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/11.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/12.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/13.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/14.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/15.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/16.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/17.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/18.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/19.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/20.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/21.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/22.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/23.mp4',
      'https://store.vvhan.com/d/Onedrive/Video/DARLING%20in%20the%20FRANXX/24.mp4',
    ],
  },
  {
    key: 'SBPK',
    name: '赛博朋克-霓虹中国',
    type: true,
    urls: [
      'https://store.vvhan.com/d/Onedrive/Video/%E8%B5%9B%E5%8D%9A%E6%9C%8B%E5%85%8B-%E9%9C%93%E8%99%B9%E4%B8%AD%E5%9B%BD.mp4',
    ],
  },
  {
    key: 'HKDGUO',
    name: '黑客帝国系列',
    type: true,
    urls: [
      'https://store.vvhan.com/d/Onedrive/Video/%E9%BB%91%E5%AE%A2%E5%B8%9D%E5%9B%BD/The.Matrix.1999.mkv',
      'https://store.vvhan.com/d/Onedrive/Video/%E9%BB%91%E5%AE%A2%E5%B8%9D%E5%9B%BD/The.Matrix.Reloaded.2003.mkv',
      'https://store.vvhan.com/d/Onedrive/Video/%E9%BB%91%E5%AE%A2%E5%B8%9D%E5%9B%BD/The.Matrix.Revolutions.2003-3.mkv',
      'https://store.vvhan.com/d/Onedrive/Video/%E9%BB%91%E5%AE%A2%E5%B8%9D%E5%9B%BD/The.Matrix.Resurrections.2021.mkv',
    ],
  },
  {
    key: 'SGLvsJG',
    name: '哥斯拉大战金刚',
    type: true,
    urls: [
      'https://store.vvhan.com/d/Onedrive/Video/%E5%93%A5%E6%96%AF%E6%8B%89%E5%A4%A7%E6%88%98%E9%87%91%E5%88%9A-1080P.mp4',
    ],
  },
  {
    key: 'YDZG',
    name: '韵动中国2019',
    type: true,
    urls: [
      'https://store.vvhan.com/d/Onedrive/Video/%E9%9F%B5%E5%8A%A8%E4%B8%AD%E5%9B%BD/%E9%9F%B5%E5%8A%A8%E4%B8%AD%E5%9B%BD_www.vvhan.com.mp4',
    ],
  },
  {
    key: 'TingWai',
    name: '庭外',
    type: true,
    urls: [
      'https://share.4ce.cn/m3u8/TingWai/1.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/2.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/3.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/4.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/5.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/6.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/7.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/8.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/9.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/10.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/11.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/12.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/13.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/14.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/15.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/16.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/17.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/18.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/19.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/20.m3u8',
      'https://share.4ce.cn/m3u8/TingWai/21.m3u8',
    ],
  },
  {
    key: 'XiaoXiaoJieMen',
    name: '小小姐们 작은 아씨들',
    type: true,
    urls: [
      'https://share.4ce.cn/m3u8/XiaoXiaoJieMen/1.m3u8',
      'https://share.4ce.cn/m3u8/XiaoXiaoJieMen/2.m3u8',
      'https://share.4ce.cn/m3u8/XiaoXiaoJieMen/3.m3u8',
      'https://share.4ce.cn/m3u8/XiaoXiaoJieMen/4.m3u8',
      'https://share.4ce.cn/m3u8/XiaoXiaoJieMen/5.m3u8',
      'https://share.4ce.cn/m3u8/XiaoXiaoJieMen/6.m3u8',
      'https://share.4ce.cn/m3u8/XiaoXiaoJieMen/7.m3u8',
      'https://share.4ce.cn/m3u8/XiaoXiaoJieMen/8.m3u8',
      'https://share.4ce.cn/m3u8/XiaoXiaoJieMen/9.m3u8',
      'https://share.4ce.cn/m3u8/XiaoXiaoJieMen/10.m3u8',
    ],
  },
  {
    key: 'QingQingRiChang',
    name: '卿卿日常',
    type: true,
    urls: [
      'https://share.4ce.cn/m3u8/QingQingRiChang/1.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/2.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/3.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/4.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/5.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/6.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/7.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/8.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/9.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/10.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/11.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/12.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/13.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/14.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/15.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/16.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/17.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/18.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/19.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/20.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/21.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/22.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/23.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/24.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/25.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/26.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/27.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/28.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/29.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/30.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/31.m3u8',
      'https://share.4ce.cn/m3u8/QingQingRiChang/32.m3u8',
    ],
  },
  {
    key: 'DianRanWo',
    name: '点燃我 温暖你',
    type: true,
    urls: [
      'https://share.4ce.cn/m3u8/DianRanWo/1.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/2.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/3.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/4.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/5.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/6.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/7.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/8.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/9.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/10.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/11.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/12.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/13.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/14.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/15.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/16.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/17.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/18.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/19.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/20.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/21.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/22.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/23.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/24.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/25.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/26.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/27.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/28.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/29.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/30.m3u8',
      'https://share.4ce.cn/m3u8/DianRanWo/31.m3u8',
      'https://europe.olemovienews.com/ts2/20221130/scJgdzBn/mp4/scJgdzBn.mp4/master.m3u8',
      'https://europe.olemovienews.com/ts2/20221130/IFojpvkk/mp4/IFojpvkk.mp4/master.m3u8',
      'https://europe.olemovienews.com/ts2/20221130/ctucaDcI/mp4/ctucaDcI.mp4/master.m3u8',
      'https://europe.olemovienews.com/ts2/20221130/zGurBayA/mp4/zGurBayA.mp4/master.m3u8',
      'https://europe.olemovienews.com/ts2/20221130/hGJukxFu/mp4/hGJukxFu.mp4/master.m3u8',
    ],
  },
  {
    key: 'YuZui',
    name: '余罪 1、2 季',
    type: true,
    urls: [
      'https://share.4ce.cn/m3u8/YuZui/1.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/2.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/3.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/4.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/5.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/6.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/7.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/8.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/9.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/10.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/11.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/12.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/13.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/14.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/15.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/16.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/17.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/18.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/19.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/20.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/21.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/22.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/23.m3u8',
      'https://share.4ce.cn/m3u8/YuZui/24.m3u8',
    ],
  },
  {
    key: 'YinRuChenYan',
    name: '隐入尘烟',
    type: true,
    urls: ['https://share.4ce.cn/m3u8/YinRuChenYan/YinRuRenYan.m3u8'],
  },
  {
    key: 'KuangBiao',
    name: '狂飙',
    type: true,
    urls: [
      'https://vip.lz-cdn3.com/20230114/17154_db8125dc/1800k/hls/mixed.m3u8',
      'https://vip.lz-cdn3.com/20230114/17153_ab4d621a/1800k/hls/mixed.m3u8',
      'https://vip.lz-cdn3.com/20230114/17152_a52e37f9/1800k/hls/mixed.m3u8',
      'https://vip.lz-cdn3.com/20230114/17151_4542e04e/1800k/hls/mixed.m3u8',
      'https://vip.lz-cdn3.com/20230114/17150_abade231/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230115/23356_f73a5483/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230115/23355_f43e640a/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230116/23419_27b37cf8/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230116/23418_139fc861/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230117/23466_0094544a/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230117/23465_e341c79f/1800k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230118/14_5c240b76/2000k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230118/13_66033d12/2000k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230119/21_7325557b/2000k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230119/20_8d21dc5b/2000k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230120/40_e60ad997/2000k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230121/23543_4f58c1fd/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230122/23566_c2819f34/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230122/23565_0c2ae732/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230123/23579_1a67afc6/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230123/23578_e3d66498/1800k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230124/115_aa734ff7/2000k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230124/114_d696d594/2000k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230125/23613_326a56c0/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230125/23612_9783c1ee/1800k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230126/155_7258f3cc/2000k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230127/198_7877b31c/2000k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230127/197_e1fe4c05/2000k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230127/196_604186b7/2000k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230128/23649_fd06986f/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230128/23648_622b2fa9/1800k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230129/248_18bd48a7/2000k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230129/247_61a22a1f/2000k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230130/23690_ec7e6c19/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230130/23689_605a61b6/1800k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230131/297_cc76dd2f/2000k/hls/mixed.m3u8',
      'https://hd.lz-cdn18.com/20230131/296_f35955e8/2000k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230201/23716_c10840ec/1800k/hls/mixed.m3u8',
      'https://vip.lzcdn2.com/20230201/23715_44218045/1800k/hls/mixed.m3u8',
    ],
  },
  {
    key: 'ZhuoZHuoFengLiu',
    name: '灼灼风流',
    type: true,
    urls: [
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第01集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第02集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第03集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第04集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第05集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第06集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第07集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第08集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第09集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第10集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第11集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第12集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第13集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第14集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第15集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第16集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第17集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第18集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第19集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第20集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第21集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第22集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第23集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第24集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第25集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第26集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第27集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第28集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第29集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第30集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第31集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第32集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第33集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第34集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第35集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第36集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第37集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第38集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第39集/index.m3u8',
      'https://s5.bfzycdn.com/video/zhuozhuofengliu/第40集/index.m3u8'
    ],
  },
];
